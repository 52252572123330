import React, { useState } from "react";
import { useSelector } from "react-redux";
import MainHeader from "../../components/MainHeader";

import logo from "../../assets/homeTriangle/oxeTechLabLogoON.png";

import Turmas from "../../components/TurmasOxetechLab";
import Card from "../../components/VagasOxetechLab";
import './styles.css'
import { createTheme } from "@mui/material";
import { useAuth } from "../../hooks/auth/AuthContext";
import { Container, Logo, TabsCursos } from "./styles";
import { NavLink } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D46D0D",
    },
  },
});

function OxetechLabDivulgacao() {
  const signed = useSelector((state) => state.auth.signed);
  const { adminUser } = useAuth();
  const tabs = [
    {
      label: "Turmas Disponíveis",
      componente: <Card className="card" />,
    },
    {
      label: "Minhas Turmas",
      componente: <Turmas className="card" />,
    },
  ];

  const [professor, setProfessor] = useState(false);
  const toggleProfessor = () => setProfessor(!professor);

  const courses = [
    {
      curso: "Lógica de Programação",
      local: "Maceió - Alagoas",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Desenvolvimento com Goolang",
      local: "Maceió - Alagoas",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Gestão de mídias sociais",
      local: "Batalha - Alagoas",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Informática Básica",
      local: "Santana do Ipanema - Alagoas",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Informática Básica",
      local: "Batalha - Alagoas",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Introdução ao Javascript",
      local: "Delmiro Gouveia",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Python",
      local: "São Miguel dos Campos",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Redes e Infraestrutura",
      local: "Pão de Açúcar",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Programação com Java",
      local: "Benedito Bentes",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Power B.I",
      local: "Santana do Ipanema",
      cargaHoraria: "40 hrs",
    },
    {
      curso: "Informática Básica",
      local: "Batalha",
      cargaHoraria: "30 hrs",
    },
    {
      curso: "Intensivão Python e C",
      local: "Benedito Bentes",
      cargaHoraria: "30 hrs",
    },
  ];

  return (

    <Container>
      {!signed && <MainHeader />}
      <Logo>
        <img src={logo} className="img" alt="Oxe-tech_Lab" />
        <p className="subtitulo">Turmas que serão abertas em breve!</p>
      </Logo>
      <div className="job-cards-container">
        {courses.map((course, index) => (
          <div className="job-card" key={index}>
            <div className="job-card-header">
              <div className="job-logo">
                <img src="logo-placeholder.png" alt={course.curso} />
              </div>
              <div className="job-buttons">
                <NavLink to="/select-cadastro" className="apply-button">
                  Cadastre-se
                </NavLink>
              </div>
            </div>
            <div className="job-card-body">
              <h3>{course.curso}</h3>
              <p>
                Local: <strong>{course.local}</strong> ·
                <span className="highlight"> Inscrições à divulgar</span>
              </p>
              <div className="job-details">
                <div className="job-detail">Carga horária ⏳ {course.cargaHoraria}</div>
              </div>
              <div className="job-footer">
                <p>Não fique de fora, inscreva-se</p>
              </div>
            </div>
          </div>
        ))}
      </div>

    </Container>
  );
}

export default OxetechLabDivulgacao;
