import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { gradienteLaranjaIntermediario} from '../../utils/styledVariables'

export const Caixa = styled(Box)(({ theme }) => ({
  // backgroundColor: 'inherit',
  // boxShadow: 'none',
  // verticalAlign: 'middle',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // margin: theme.spacing(1),
  // textAlign: 'left',
  // color: theme.palette.text.secondary,
  '.content': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    padding:"2em",
    gap:"3em",
    
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
}))


export const ButtonOff = styled(Button)`
  border: none;
  color: white;
  font-family: 'Inter', sans-serif;
  text-transform: none;
  font-size: 11px;
  font-weight:'medium';
  margin: 5px;
  :hover {
    padding-bottom: 1;
    border-bottom: 2px solid;
    border-radius: 15;
    border-image: ${gradienteLaranjaIntermediario} 1; //linear-gradient(to right, darkblue, darkorchid) 1;
    background: transparent;
  }
  .activeLink {
    background-color: darkblue;
  }
  .normalLink {
    background-color: darkblue;
  }
`
export const ButtonOn = styled(Button)`
  border: none;
  color: white;
  font-family: 'Inter', sans-serif;
  padding-bottom: 1;
  border-bottom: 2px solid;
  border-radius: 15;
  border-image: ${gradienteLaranjaIntermediario} 1; //linear-gradient(to right, darkblue, darkorchid) 1;
  background: transparent;
  text-transform: capitalize;
  font-size: 11px;
  font-weight:'medium';
  margin: 5px;
  :hover {
    border: none;
  }
`

