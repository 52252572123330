import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Link } from 'react-router-dom'
import './styles.css';

import { Item, Caixa, Mapa } from './styles'
import MapaInovacaoFig1 from '../../../src/assets/MapaInovacaoFig1.svg'
// import MapaInovacaoFig2 from '../../../src/assets/MapaInovacaoFig2.svg'
// import MapaInovacaoFig from '../../../src/assets/MapaInovacaoFig3.svg'
import MapaInovacaoFig from '../../../src/assets/Mapa.png';
import { gradienteLaranja } from '../../utils/styledVariables';
import { NavLink } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";


export default function MapaInovacao() {
  return (
    <div className='containerMapa'>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "90%",
        flexDirection: "row",
        textAlign: 'left',
        backgroundColor: '#fdf9f0',
        padding: '4em',
        flexWrap:'wrap'



      }}>

        <div className='textMapaContainer'>
          <h2 className="titulo">Nosso Mapa <br/>da Inovação</h2>
          <p
            className='subtituloMapa'
          >
            Descubra o Ecossistema de Inovação de
            Alagoas! Explore o Ecossistema de
            Inovação com um mapa interativo que
            reúne profissionais, empresas e instituições
            de ciência e tecnologia. Conheça a
            localização, descrição e perfil de cada
            iniciativa, tudo em um único lugar.
          </p>
          <NavLink className="linkMapa" to="/inovation-map">Acesse<BsArrowRightShort style={{verticalAlign:'middle'}} size={25}/></NavLink>
        </div>

        <div className='imgMapaContainer'>
          <img
            src={MapaInovacaoFig}
            alt="MapaInovacaoFig2"
            style={{
              maxWidth: "100%",
              maxHeight: "auto",
              objectFit: "contain",
              filter: "drop-shadow(1.95px 20px 8px rgba(0, 0, 0, 0.35))",



            }} />
          <div className="semi-circle">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>

      </div>


    </div>
  )
}