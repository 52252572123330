import "./config/ReactotronConfig";

import React from "react";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import Routes from "./routes";
import history from "./services/history";

import { store, persistor } from "./store";

import GlobalStyle from "./styles/global";

import { AnimateSharedLayout } from "framer-motion";
import FooterLaccan from "./components/FooterLaccan";
import { AuthProvider } from "./hooks/auth/AuthContext";
import ScrollToTop from "./store/scrollToTop";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <AnimateSharedLayout>
            <AuthProvider>
            <ScrollToTop />
              <Routes />
            </AuthProvider>

            
            <GlobalStyle />
          </AnimateSharedLayout>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
