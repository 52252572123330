import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";

import Paper from "@mui/material/Paper";

export const BoxPubicoAlvo = styled("div")(({ theme }) => ({
  background:"black",
  padding: theme.spacing(1),
  border: "1px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "10px",
  margin: theme.spacing(4),
  textAlign: "center",
  color: "#FFFFFF",
  flexGrow: 1,
}));
export const Caixa = styled(Box)(({ theme }) => ({
  padding: "5%",
  minHeight: "100vh",
  ".container": {
    background: 'black',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection:'column',
    width:"100%",
    "@media (max-width: 800px)": {
      flexDirection: "column",
    },
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  height: "100%",
  verticalAlign: "middle",
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  color: theme.palette.text.secondary,
  ".publico": {
    fontSize: "450%",
    fontFamily: ["Poppins", "sans-serif"].join(","),
    color: "white",
    "@media (min-width: 1500px)": {
      fontSize: "5em",
      marginTop: "3vh",
    },
  },
  ".subtext": {
    textAlign: "left",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: 100,
    fontSize: "1.3em",
    fontStyle: "normal",
    marginTop: "15px",
    "@media (max-width: 800px)": {
      fontSize: "1.7em",
      textAlign: "center",
    },
    "@media (min-width: 1500px)": {
      fontSize: "2em",
    },
  },
  ".subtextS": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
  "@media (max-width: 800px)": {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  ".text": {
    "@media (max-width: 800px)": {
      fontSize: "0.5rem",
    },
  },
}));
