import { makeStyles } from '@material-ui/styles'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Tabline = styled(Box)`
  max-width: 80vw;
  height: auto;
  position: absolute;
  top: 22.3%;
  left: 18%;
  z-index: 2;
  @media (max-width: 1500px){
    top:21%;
    
  }
  @media (max-width: 1303px){
    top:19%;
  }
  @media (max-width: 1250px){
    top:16.5%;
  }
  @media (max-width: 1150px){
    top:16%;
  }
  @media (max-width: 1000px){
    top:13.5%;
  }
  
`
export const useStyles = makeStyles(theme => ({
  app: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    
    
  },
  tab1: {
    backgroundColor: "#df622e",
    padding: 0,
    width: '10%',
    borderRadius: '20px 20px 0px 0px',
  },
  tab2: {
    marginLeft:'1rem',
    backgroundColor: "#df622e",
    padding: 0,
    width: '10%',
    borderRadius: '20px 20px 0px 0px'
  },
  tab3: {
    marginLeft:'1rem',
    backgroundColor: "#df622e",
    padding: 0,
    width: '10%',
    borderRadius: '20px 20px 0px 0px'
  },
  taboff: {
    marginLeft:'1rem',
    backgroundColor: '#9ab1bf',
    padding: 0,
    width: '10%',
    borderRadius: '20px 20px 0px 0px',
    "&:first-child": {
      marginLeft: "0", // Remove a margem do primeiro item
    },
  },
  
  indicator: {
    display: 'none',
    
  },
  oxeLab: {
    backgroundColor: "#FFF",
    borderRadius: '0px 15px 15px 15px',
    display: 'flex',
    flexDirection: 'row',
    height: '16vh',
    padding:'0'
    
  },
  oxeDin: {
    background: "#FFF",
    borderRadius: '0px 15px 15px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '16vh',
    padding:'0'
    
  },
  oxeWork: {
    background: "#FFF",
    borderColor: 'rgba(0, 0, 0, 0.49)',
    borderRadius: '0px 15px 15px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '16vh',
    padding:'0'
   
  },
}))

export const ButtonLab = styled(Button)`
  :hover{
    background-color: #DD7A1E;
  }
`

export const Title = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  
  .content {
    width: 100vw;
  }
  .titulo {
    color: #fff;
    font-size: 450%;
    animation: fadeIn;
    animation-duration: 2s;
    /* @media (max-width: 800px) {
        text-align: center;
      } */
    @media (min-width: 1500px) {
      font-size: 4rem;
    }
  }
  .tituloDin {
    color: #fff;
    font-size: 400%;
    animation: fadeIn;
    animation-duration: 2s;
  }

  .subtitulo {
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    animation: fadeIn;
    animation-duration: 2s;
    text-align: justify;
    
    /* @media (max-width: 800px) {
      text-align: center;
    } */
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
  /* @media (max-width: 800px) {
    width: 90vw;
  } */
  .oxedin {
    width: 60vw;
  }

  .listas {
    color: #fff;
    animation: fadeIn;
    animation-duration: 2s;
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
`


