import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import "animate.css";
import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { FiLogIn } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MainHeader from "../../components/MainHeader";
import { useAuth } from "../../hooks/auth/AuthContext";
import {UserOutlined, LockFilled} from '@ant-design/icons';

import {
  gradienteLaranja180,
  laranjaIntermediario,
  laranjaMain,
  profileWhiteShade,
} from "../../utils/styledVariables";
import Rodape from "../../components/Rodape";
import './styles.css';
import HeaderSecti from "../../components/HeaderSecti";

const brancoBackground = "#fdf9f0";

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family:"Montserrat";
  &:focus,
  &:visited,
  &:link {
    text-decoration: none;
  }

  color: ${brancoBackground};
  &:hover,
  &:active {
    color: ${brancoBackground};
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
  }
`;

const StyledLabel = styled(Form.Item)`
  .ant-form-item-label > label {
    color: black;
    font-size: 1.5rem;
    width:100%;
    font-family:"Montserrat";
  },
  .inputLogin {
    border-radius: 5rem;
    padding: 0.8rem;
    width: 100%;
    font-family:"Montserrat";
  }
`;

const BlueBox = styled(Box)`
  width: 36rem;
  height: 42rem;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 90px;
  bottom: 0px;
  margin: auto auto auto auto;
  border-radius: 0.5rem;
  color: #fff;
  font-family:"Montserrat";
  text-align: center;
  alignItens: center;
  justifyContent:center;
  background: transparent; //rgba(79, 105, 170, 89%);
  animation: fadeIn;
  animation-duration: 1s;
  @media (max-width: 700px) {
    width: 38rem;
    height: 45rem;
  }
  @media (max-width: 360px) {
    width: 33rem;
    height: 45rem;
  }
`;

function SignInAluno2() {
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { value } = e.target;

    if (/[^0-9]/.test(value)) {
      setErrorMessage("Apenas números são permitidos.");
    } else {
      setErrorMessage("");
    }
  };
  const UseStyles = makeStyles((theme) => ({
    root: {
      overflow: "hidden",
      background: "#df622e",
      //'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)',
      height: "60vh",
    },
    Button: {
      "&:hover": {
        backgroundColor: "#004aad",
        color: "#fdf9f0",
      },
      display: "flex",
      width: "100%",
      height: "4rem",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      background: "#255aa7", //'#741F8A',
      borderRadius: "5rem",
      color: profileWhiteShade,
      fontSize: "1rem",
      fontWeight: "bold",
      fontFamily:"Montserrat",
      fontStyle:'Montserrat',
      letterSpacing:'1px'
    },
    Link: {
      color: "white",
    },

  }));

  const dispatch = useDispatch();
  const { login } = useAuth();

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 25,
    },
  };

  const onFinish = async ({ credential, password }) => {
    login({ credential, password });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const classes = UseStyles();

  return (
    <>
      <HeaderSecti/>
      <MainHeader page="login" />
      <div className={classes.root}>
        <div className={classes.paperContainer} />
        <BlueBox>
          <div>
            <Typography
              color="#fdf9f0"
              fontFamily="Montserrat"
              fontWeight="bold"
              fontSize="3.7rem"
              variant="h3"
              component="div"
              gutterBottom
              sx={{
                mt: 4,
              }}
            >
              Login
            </Typography>
            <Stack
              spacing={6}
              sx={{
                mt: 6,
                width: "30rem",
                height: "30rem",
                mr: "auto",
                ml: "auto",
              }}
            >
              <Form
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                style={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <StyledLabel
                  name="credential"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório.",
                    },
                  ]}
                  validateStatus={errorMessage ? "error" : ""}
                  help={errorMessage}
                >
                  
                  <Input
                    prefix={<UserOutlined size='20px' style={{color: '#df622e', padding:'5px'}}/>}
                    className="inputLogin"
                    placeholder="CPF / CNPJ"
                    onChange={handleInputChange}
                  />
                </StyledLabel>

                <StyledLabel
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "A Senha é obrigatória",
                    },
                  ]}
                >
                  
                  <Input.Password 
                    prefix={<LockFilled style={{color: '#df622e',backgroundColor:'#FFF', padding:'5px'}}/>}
                  className="inputLogin" placeholder="Senha"/>
                </StyledLabel>
                <Form.Item>
                  <Button htmlType="submit" className={classes.Button}>
                    ENTRAR
                  </Button>
                </Form.Item>
                <StyledLink to="/ajuda">
                  <FiLogIn />
                  Preciso de ajuda
                </StyledLink>
              </Form>
            </Stack>
          </div>
        </BlueBox>

      </div>
      <Rodape />
    </>
  );
}

export default SignInAluno2;
