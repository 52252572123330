import LogoAl from '../../assets/logo_gov_al.png';
import './styles.css';
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { BsTwitterX, BsFillInfoCircleFill, BsQuestionCircleFill } from "react-icons/bs";
import { FcInfo, FcAssistant } from "react-icons/fc";

export default function HeaderSecti() {
    return (
        <div className='headerSecti'>
            <div className='imgLogoAl'>
                <img src={LogoAl} />
            </div>

            <h1 className='titleHeaderSecti'>SECTI</h1>
            <div className="iconsSectiContainer">
                <a className='linkSiteSecti' href='https://secti.al.gov.br/'>ACESSE O SITE AGORA</a>
                <a className='iconsSecti' href='https://e-ouv.al.gov.br/'><FcAssistant size={18}/></a>
                <a className='iconsSecti' href='https://secti.al.gov.br/solicitacao-de-informacao'><FcInfo size={18}/></a>
                <a className='iconsSecti' href='https://www.facebook.com/secti.alagoas/'><FaFacebook color='#fdf9f0'/></a>
                <a className='iconsSecti' href='https://www.instagram.com/sectialagoas/'><FaInstagram color='#fdf9f0'/></a>
                <a className='iconsSecti' href='https://x.com/i/flow/login?redirect_after_login=%2Fsectialagoas'><BsTwitterX color='#fdf9f0'/></a>
                <a className='iconsSecti' href='https://www.youtube.com/user/Sectialagoas'><FaYoutube color='#fdf9f0'/></a>
            </div>


        </div>
    )
}