import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Introducao, Title, Programas } from "./styles";
import "animate.css";
import MainHeader from "../../components/MainHeader";
import NewsFeed from "../../components/NewsFeed";
import Programs from "../../components/Programs";
import ProgramsMobile from "../../components/ProgramsMobile";
import MapaInovacao from "../../components/MapaInovacao";
import SobreNos from "../../components/SobreNos";
import Introducao2 from "../../components/Introducao";
import Rodape from "../../components/Rodape";
import banner from "../../assets/banner.png";
import { store } from "../../store";
import PersonBanner from '../../assets/person_banner.png';

import { Box } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HeaderSecti from "../../components/HeaderSecti";
import SobreProgramas from "../../components/SobreProgramas";
import InovacaoCapacitacao from "../../components/InovacaoData";
import { NavLink } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { IoLogoFacebook } from "react-icons/io";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { useRef } from "react";


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function Main() {
  const { signed } = store.getState().auth;


  const inovacaoRef = useRef(null);

  // Função para rolar até o componente
  const scrollToInovacao = () => {
    if (inovacaoRef.current) {
      inovacaoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <HeaderSecti />
        {!signed && <MainHeader scrollToInovacao={scrollToInovacao}/>}
        <Introducao>
            
          <Title>
            <div className="semi-circle3">
              <div className="circle3"></div>
            </div>
            <h2 className="welcomeText">Bem-vindo ao <span>OXETECH</span></h2>
            <h1 className="titulo">
              Conectando <br />Alagoas ao futuro
            </h1>
            <h4 className="subtitulo ">
              Ciência, tecnologia e inovação ao seu alcance! O
              OxeTech oferece <span>capacitação, networking e vagas de
                trabalho</span> para impulsionar a economia de Alagoas.
              Descubra como fazer parte dessa revolução digital.
            </h4>
            <div style={{ marginTop: '2rem' }}>
              <NavLink className="linkCadastro" to="/select-cadastro">Cadastre-se<BsArrowRightShort style={{ verticalAlign: 'middle' }} size={25} /></NavLink>
            </div>
            
          </Title>

          <div className="image-section">
            <div className="background-circle"></div>
            <img
              src={PersonBanner}
              alt="Estudante com laptop"
              className="central-image"
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '1em' }}>
              <a href='https://www.facebook.com/secti.alagoas/'><IoLogoFacebook color="#b53231" size={40} /></a>
              <a href='https://x.com/i/flow/login?redirect_after_login=%2Fsectialagoas'><FaXTwitter color="#b53231" size={40} /></a>
              <a href='https://www.instagram.com/sectialagoas/'><FaInstagram color="#b53231" size={40} /></a>
            </div>
            <div className="semi-circle">
              <div className="circle"></div>
            </div>
            <div className="semi-circle2">
              <div className="circle2"></div>
            </div>
          </div>

        </Introducao>

        <Programas>

          <Box sx={{ width: "100%", display: { xs: "flex", sm: "none" } }}>
            <ProgramsMobile />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <Programs />
          </Box>
        </Programas>
        <div ref={inovacaoRef}>
          <InovacaoCapacitacao />
        </div>

        <SobreProgramas />
        <MapaInovacao />
        <SobreNos />

        <Rodape />
      </Container>
    </ThemeProvider>
  );
}

export default Main;


