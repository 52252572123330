import { styled } from '@mui/material/styles'
import VerMapafig from '../../../src/assets/VerMapa.png'

import Paper from '@mui/material/Paper'

export const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  height: 'auto',
  display: 'flex',
  alignItems:'center',
  justifyContent:'center',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  '.titulo': {
    color: '#df622e',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '3em',
    margin: '0px',
    padding: '0px',
    '@media (max-width: 800px)': {
      fontSize: '4rem'
    },
    '@media (max-width: 320px)': {
      fontSize: '3rem'
    }
  },
  '.img': {
    '@media (max-width: 800px)': {
      width: '90%'
    }
  },
  '.subtitulo': {
    textAlign: 'left',
    color: '#33333',
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontStyle: 'normal',
    
    '@media (max-width: 800px)': {
      fontSize: '0.5rem',
      textAlign: 'center'
    },
    '@media (min-width: 1500px)': {
      fontSize: '2em'
    }
  }
}))

export const Caixa = styled(Paper)(({ theme }) => ({
  padding: '5%',
  // height: '90vh',
  '.container': {
    background: '#fdf9f0',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      // minHeight: '0',
      height: 'auto'
    }
  }
}))
