import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import AlunoRoute from './AlunoRoute'
import EmpresaRoute from './EmpresaRoute'
import AdminRoute from './AdminRoute'

import Main from '../pages/Main';
import MainInovationMap from '../pages/MainInovationMap';
import MainOxeTech from '../pages/MainOxe-Tech';
import MainOxeTechLab from '../pages/MainOxe-TechLab';
import MainOxeTechWork from '../pages/MainOxe-techWork';
import MainOxeDin from '../pages/MainOxeDin';
import OxeTechWorkInicial from '../pages/OxeTechWorkInicial';
import Sobre from '../pages/Sobre';

import Confirm from '../pages/Confirm/index';
import SignIn from '../pages/SignIn';
import SignOut from '../pages/SignOut';
import SignUpAluno from '../pages/SignUpAluno';
import Sucess from '../pages/Sucess';
// import AlunoPage from '../pages/AlunoPage'
import DashboardVaga from '../components/DashboardVaga';
import BancoTalentos from '../pages/BancoTalentos';
import DashAdmin from '../pages/DashAdmin';
import Dashboard from '../pages/Dashboard';
import EditProfileAluno from '../pages/EditProfileAluno';
import EditProfileEmpresa from '../pages/EditProfileEmpresa';
import EmailRecovery from '../pages/EmailRecovery';
import Error from '../pages/Error';
import ForgotPasword from '../pages/ForgotPassword/index';
import Help from '../pages/Help';
import InovationMap from '../pages/InovationMap';
import OxeTechWork from '../pages/OxeTechWork';
import Oxedin from '../pages/Oxedin';
import OxeTechWorkInscricao from '../pages/OxetechWorkInscricao';
import OxeTechWorkInscritos from '../pages/OxetechWorkInscritos';
import OxeTechLab from '../pages/Oxetech_Lab';
import PageAlunos from '../pages/PageAlunos';
import PageCursos from '../pages/PageCursos';
import PageVagaAdm from '../pages/PageVagaAdm';
import PageVagaAluno from '../pages/PageVagaAluno';
import PageVagaEmpresa from '../pages/PageVagaEmpresa';
import PageVagasAdm from '../pages/PageVagasAdm';
import PageVagasAlunos from '../pages/PageVagasAlunos';
import PrivacyPolices from '../pages/PrivacyPolices';
import ProfileAlunoAdm from '../pages/ProfileAlunoAdm';
import ProfileAlunoPublic from '../pages/ProfileAlunoPublic';
import SelectsignUp2 from '../pages/SelectSignUp';
import SignUpEmpresa from '../pages/SignUpEmpresa';
import SignUpFinished from '../pages/SignUpFinished';
import DashLab from '../pages/DashLab';
import DashOXL from '../pages/DashOXL';
import GerenciamentoAdmin from '../pages/GerenciamentoAdmin';
import { AdmCursos } from '../components/DashUserAdm/AdmCursos';
import PageTrilha from '../pages/PageTrilha';
import PageCursoTrilha from '../components/PageCursoTrilha';
import PageTrilhaDeConhecimento from '../components/PageTrilhaDeConhecimento';
// import AtividadeAluno from '../components/AtividadesAlunos';
import PageTrilhaNew from '../pages/PageTrilhaNew';
import PerfilEscola from '../OxeTechEdu/pages/Bolsista/PerfilPrincipal/index'
import OxetechEdu from '../OxeTechEdu/pages/Estudante/PaginaPrincipal/index'
import DashEscola from '../OxeTechEdu/pages/Bolsista/DashboardTurma'

import {ValidarAlunos} from '../OxeTechEdu/pages/ValidarAlunos/index'
import DashOXD from '../OxeTechEdu/pages/DashOxd';
import { Acompanhamento } from '../OxeTechEdu/pages/Acompanhamento';
import OxetechLabDivulgacao from '../pages/Oxetech_lab_Divulgacao';
export default function Routes() {
  return (
    <Switch>
      {/* ROTAS PÚBLICAS */}
      <Route path="/" exact component={Main} />
      {/* <Route path="/dash-oxe-din" exact component={MainOxeDin} /> */}
      {/* <Route path="/dash-oxe-tech-work" exact component={MainOxeTechWork} /> */}
      {/* <Route path="/dash-oxe-tech-lab" exact component={MainOxeTechLab} /> */}
      {/* <Route path="/dash-oxe-tech" exact component={MainOxeTech} /> */}
      {/* <Route path="/dash-inovation-map" exact component={MainInovationMap} /> */}
      {/* <Route path="/sobre" exact component={Sobre} /> */}
      <Route path="/oxe-tech-work" exact component={OxeTechWorkInicial} />
      <Route path="/oxetechlab-cursos" exact component={OxetechLabDivulgacao}/>

      <Route path="/login" exact component={SignIn} />
      <Route path="/logout" exact component={SignOut} />
      <Route path="/sucess" component={Confirm} />
      {/* <Route path="/finished-sucess" component={Sucess} /> */}
      <Route path="/forgot" component={ForgotPasword} />
      <Route path="/cadastro" component={SignUpAluno} />
      <Route path="/empresa-cadastro" component={SignUpEmpresa} />
      <Route path="/select-cadastro" component={SelectsignUp2} />
      <Route path="/politicas-privacidade" component={PrivacyPolices} />
      {/* <Route path="/finished/:id" component={SignUpFinished} /> */}
      <Route path="/inovation-map" component={InovationMap} />
      <Route path="/oxedin" component={Oxedin} />
      <Route path="/oxetechwork" component={OxeTechWork} />
      <Route path="/oxetechlab" component={OxeTechLab}/>
      <Route path="/oxetechedu" component={OxetechEdu} />
      {/* <Route path="/dashboard" component={AlunoPage} isPrivate /> */}

      {/* ROTAS PARA USUÁRIO PESSOA FÍSICA */}
        <AlunoRoute path="/oxetrilhas" component={PageTrilhaNew}/>
        <AlunoRoute path="/dashboard" component={Dashboard} />
        <AlunoRoute path="/account" component={EditProfileAluno}/>
        <AlunoRoute
          path="/oxetechwork-inscricao"
          component={OxeTechWorkInscricao}
        />
        {/* OxetechLab */}
          <AlunoRoute path="/perfil-laboratorio/:labId" component={DashLab} isLab/>
          <AlunoRoute path="/laboratorio/:labId/turma-selecao-painel/:id" component={DashLab} isLab />
          <AlunoRoute path="/laboratorio/:labId/turma-iniciada-painel/:id" component={DashLab} isLab/>
          {/* <Route path="/oxetechlab-mural" component={DashLab} isPrivate /> */}
          {/* <AlunoRoute path="/oxetechlab-turmas" component={Dashboard} /> */}

        {/* Routes Trilha de Conhecimento */}
          <AlunoRoute path="/trilha/:idTrilha/curso/:idCurso" component={PageCursoTrilha} />
          <AlunoRoute path="/oxetrilha/:id" component={PageTrilhaDeConhecimento} />
        {/* <Route path="/oxetrilha" component={PageTrilha} isPrivate/> */}
        {/* <Route path="/trilha-atividade/:id" component={AtividadeAluno} isPrivate/> */}

        {/* Routes OxetechEdu */}
          <AlunoRoute path="/escola/:escolaId" component={PerfilEscola} isEdu/>
          <AlunoRoute path="/validar/:escolaId" component={ValidarAlunos} isEdu/>
          <AlunoRoute path="/dash/:escolaId/turma/:turmaId" component={DashEscola} isEdu />
          <AlunoRoute path="/acompanhamento/:escolaId" component={Acompanhamento} isEdu />
          


      {/* ROTAS PARA USUÁRIO PESSOA JURÍDICA */}
        <EmpresaRoute path="/bancotalentos" component={BancoTalentos} isWork/>
        <EmpresaRoute path="/minha-vaga/:id" component={PageVagaEmpresa} />
        <EmpresaRoute path="/empresa-account" component={EditProfileEmpresa}/>
        {/* <EmpresaRoute path="/dashboard-vaga" component={DashboardVaga}/> */}
      
      {/* ROTAS PARA USUÁRIO ADMIN */}
        <AdminRoute path="/dashadmin" component={DashAdmin}/>
        <AdminRoute path="/administradores" component={GerenciamentoAdmin} isSudo />
        <AdminRoute path="/alunos" component={PageAlunos}  />
        <AdminRoute path="/oxetechlab-dashboard" component={DashOXL} />
        <AdminRoute path="/oxetechedu-dashboard" component={DashOXD} />      
        <AdminRoute path="/vagas" component={PageVagasAdm}  />
        <AdminRoute path="/aluno/:id" component={ProfileAlunoAdm} />
        <AdminRoute
          path="/oxetechwork-inscritos"
          component={OxeTechWorkInscritos}
        />
        <AdminRoute path="/trilhas-admin" component={AdmCursos} />
        
        <AdminRoute path="/showvaga/:id" component={PageVagaAdm} />
      
      
      {/* <Route path="/cursos" component={PageCursos} /> */}


      <Route path="/ajuda" component={Help} />
      {/* <Route path="/deletar-conta" component={DeleteAccount} /> */}
      <Route path="/recuperar-acesso" component={EmailRecovery} />
      <Route path="/" component={Error} />
    </Switch>
  );
}
