import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClassIcon from "@mui/icons-material/Class";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import local from "../../../../assets/perfilOXL/local.svg";
import logo from "../../../assets/logo.svg";
import manLab from "../../../../assets/perfilOXL/man-lab.svg";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { makeStyles } from "@material-ui/styles";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { signOut } from "../../../../store/modules/auth/actions";
import AccountMenu from "../../../../components/AccountMenu";
import BarChartIcon from '@mui/icons-material/BarChart';

const drawerWidth = 280;

const useStyles = makeStyles({
  paper: {
    position: "static !important",
    // backgroundColor: "rgb(142 36 170) !important",
    // border: '1px, solid red'
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  thumb: {
    color: "#e9e9e9",
  },
  rail: {
    boxShadow: "0px 4px 6px rgb(0 0 0)",
  },
  ListPadding: {
    padding: "0 !important",
  },
  SliderRoot: {
    padding: "7px 0 !important",
  },
  underlineNone: {
    "&:hover": {
      color: "red",
    },
  },
});

function Sidebar({ onMobile, collapsed, setCollapsed}) {
  const escola = useSelector((state) => state.user.escola_profile);
  const { escolaId } = useParams();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dataChanged, setDataChanged] = React.useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const routes = [
    { name: "Turmas", link: `/escola/${escolaId}`, icon: ClassIcon },
    // { name: "Editar Dados", link: "/", icon: EditIcon },
    { name: "Validação de Matricula", link: `/validar/${escolaId}`, icon: FactCheckIcon },

    { name: "Acompanhamento", link: `/acompanhamento/${escolaId}`, icon: BarChartIcon }
  ];

  const oxl_url = window.location.pathname;

  useEffect(async () => {
    try {
      const response = await api.get("/aluno/avatar", {
        responseType: "arraybuffer",
      });
      const imgUrl = URL.createObjectURL(
        new Blob([response.data], { type: "image" })
      );
      setAvatarUrl(imgUrl);
    } catch (err) {
      console.log(err);
    }
  }, []);


  const drawer = (
    <Box sx={{}}>
      <Box sx={{ position: "relative", p: 1, width: "40%" }}>
        {oxl_url === "/oxetechlab-mural" && (
          <img src={logo} alt="logo" width="100%" />
        )}
      </Box>
      <Tooltip arrow placement="bottom" title="Minha Conta">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ width: "100%" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
              width: "100%",
            }}
          >
            <img src={manLab} alt="" width="30%" />
            <div style={{ width: "50%" }}>
              <h3 style={{ color: "rgba(0, 0, 0, 0.63)", marginRight: "10%" }}>
                Escola
              </h3>
              <div
                style={{
                  display: "flex",
                  padding: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(212, 109, 13, 0.68)",
                  borderRadius: "15px",
                }}
              >
                <img src={local} alt="local" />
                <h5
                  style={{
                    margin: "0 10%",
                    color: "#fff",
                    fontSize: escola?.nome.length > 10 ? "0.6em" : "1em",
                  }}
                >
                  {escola?.nome}
                </h5>
              </div>
            </div>
          </Box>
        </IconButton>
      </Tooltip>
      <AccountMenu anchorEl={anchorEl} onClick={handleClose} current={"escola"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          marginTop: "5%",
          padding: "0 5%",
        }}
      >
        <h4 style={{ margin: 0, color: "rgba(0, 0, 0, 0.63)" }}>Endereço</h4>
        <h5 style={{ margin: 0, fontStyle: "italic" }}>
          {escola?.rua}, {escola?.bairro}, {escola?.numero},{" "}
          {escola?.municipio}, {escola?.cep} - AL
        </h5>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          marginTop: "5%",
          padding: "0 5%",
        }}
      >
        <h4 style={{ margin: 0, color: "rgba(0, 0, 0, 0.63)" }}>Professor</h4>
        <h5 style={{ margin: 0, fontStyle: "italic" }}>
          {escola?.professor.name}
        </h5>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          marginTop: "5%",
          padding: "0 5%",
        }}
      >
        <h4 style={{ margin: 0, color: "rgba(0, 0, 0, 0.63)" }}>Bolsista</h4>
        <h5 style={{ margin: 0, fontStyle: "italic" }}>
          {escola?.BolsistaOxetechEdu.Aluno.name}
        </h5>
      </Box>

      <List
  classes={{ padding: classes.ListPadding }}
  sx={{ marginTop: "10%" }}
>
  {routes.map((route, index) => (
    <div key={index}>
      <ListItem
        dense
        component={Link}
        to={route.link}
        disablePadding
        sx={{
          "&:hover": {
            backgroundColor: "rgba(212, 109, 13, 0.1)", 
          },
          backgroundColor: location.pathname === route.link ? "rgba(212, 109, 13, 0.3)" : "#fff",
        }}
      >
        <ListItemButton
          sx={{
            height: "50px",
            backgroundColor: location.pathname === route.link ? "rgba(212, 109, 13, 0.3)" : "white",
          }}
        >
          <ListItemIcon
            sx={{ minWidth: "30px", color: "rgba(212, 109, 13, 1)" }}
          >
            <route.icon />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "rgba(0, 0, 0, 0.66)" }}
            primary={route.name}
          />
        </ListItemButton>
      </ListItem>
      {index !== routes.length - 1 && ( 
        <Divider
          variant="middle"
          sx={{
            p: 0.2,
            borderColor: "rgba(0, 0, 0, 0.16)",
          }}
        />
      )}
    </div>
  ))}
</List>
      {/*  */}

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: 2,
          bottom: "12%",
          position: "absolute",
        }}
      >
        <LinkMui
          to="/dashboard"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          variant="h6"
          classes={{ underlineNone: classes.underlineNone }}
          underline="none"
          color="#000"
          component={Link}
          // onClick={handleSignOut}
        >
          <Typography>Voltar</Typography>
        </LinkMui>
      </Box> */}
    </Box>
  );

  return (
    <Box sx={{ position: "fixed", zIndex: 5 }}>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          position: "relative",
        }}
      >
        <Box>
          <IconButton
            size="small"
            sx={{
              mt: 1,
              left: "15px",
              color: "rgba(212, 109, 13, 0.68)",
              backgroundColor: mobileOpen
                ? "rgba(212, 109, 13, 0.68)"
                : "rgba(212, 109, 13, 0.68)",
            }}
            onClick={handleDrawerToggle}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          //   backgroundColor: "#8e24aa",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!onMobile && (
          <Box
            sx={{
              left: !collapsed ? "calc(100% - 35px)" : "",
              position: "absolute",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="small"
              sx={{
                mt: 1,
                ml: collapsed ? 0.5 : -0.5,
                color: "rgba(212, 109, 13, 0.68)",
                backgroundColor: !collapsed
                  ? "inherit"
                  : "rgba(212, 109, 13, 0.68)",
                "&:hover": {
                  backgroundColor: "rgba(212, 109, 13, 0.68)",
                  color: "#fff",
                  boxShadow: "5",
                },
              }}
              onClick={handleDrawerCollapsed}
            >
              {!collapsed ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: "flex", height: "100vh", background: "#FFFFF" }}>
          <Collapse
            orientation="horizontal"
            in={!collapsed}
            sx={{ boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.25)" }}
          >
            <Box
              component="nav"
              sx={{
                // backgroundColor: "#8e24aa",
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
              }}
              aria-label="left navigation drawer"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </SwipeableDrawer>

              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="permanent"
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </SwipeableDrawer>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

// export default Sidebar

export default function IntegrationNotistack({
  onMobile,
  collapsed,
  setCollapsed,
}) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Sidebar
        onMobile={onMobile}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    </SnackbarProvider>
  );
}
