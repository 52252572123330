import React from "react";
import { FaSchool, FaUserGraduate, FaBriefcase, FaTrophy, FaPlus } from "react-icons/fa";
import './styles.css';
import PersonSobre from '../../assets/person_sobre.png';
import { TiPlus } from "react-icons/ti";
import { BsCameraVideoFill, BsFillPersonFill } from "react-icons/bs";
import { store } from "../../store";
import { useMemo } from "react";

function InovacaoCapacitacao() {

    const { signed } = store.getState().auth;

    return (
        <div className="containerInovacaoData" id="inovacao-capacitacao">
            {/* Seção de métricas principais */}
            <div className={`${!signed ? "metrics" : "extra-margin"}`} >
                <div className="metric">
                    <FaSchool className="metric-icon" size={75} />
                    <div>
                        <h2>200<TiPlus size={30} color="#df622e" style={{ verticalAlign: 'middle' }} /></h2>
                        <p>Cursos realizados</p>
                    </div>

                </div>
                <div className="metric">
                    <FaUserGraduate className="metric-icon" size={75} />
                    <div>
                        <h2>10k<TiPlus size={30} color="#df622e" style={{ verticalAlign: 'middle' }} /></h2>
                        <p>Estudantes formados</p>
                    </div>

                </div>
                <div className="metric">
                    <FaBriefcase className="metric-icon" size={75} />
                    <div>
                        <h2>425<TiPlus size={30} color="#df622e" style={{ verticalAlign: 'middle' }} /></h2>
                        <p>Vagas de emprego</p>
                    </div>

                </div>
                <div className="metric">
                    <FaTrophy className="metric-icon" size={75} />
                    <div>
                        <h2>300<TiPlus size={30} color="#df622e" style={{ verticalAlign: 'middle' }} /></h2>
                        <p>Empregos efetivados</p>
                    </div>

                </div>
            </div>


            <div className="main-section">
                <div className="image-section">
                    <div className="background-circle"></div>
                    <img
                        src={PersonSobre}
                        alt="Estudante com laptop"
                        className="central-image"
                    />
                    <div className="speech-bubble video">
                        <BsCameraVideoFill style={{ verticalAlign: 'middle', marginRight: '1em' }} size={30} />
                        <p>Vídeo<br /> Chamada</p>
                    </div>
                    <div className="speech-bubble contacts">
                        <BsFillPersonFill style={{ verticalAlign: 'middle', marginRight: '1em'}} size={30} />
                        <p>Contatos <br /> e Network</p>
                    </div>


                </div>

                {/* Texto descritivo */}
                <div className="text-section">
                    <h1>Inovação e <br />capacitação</h1>
                    <p>
                        O OxeTech nasce para promover a capacitação técnica e profissional
                        dos alagoanos, incentivando o empreendedorismo e atraindo
                        investimentos. O público-alvo inclui <span>estudantes do ensino médio e superior</span>,
                        <span>professores</span> e <span>profissionais interessados em tecnologia</span>, oferecendo
                        um espaço moderno para o desenvolvimento de conhecimento.
                    </p>
                    <div className="target-audience">
                        <div className="audience-card">
                            <div className="audience-icon blue">🎓<p className="iconText">Estudantes de ensino médio e superior</p></div>

                        </div>
                        <div className="audience-card">
                            <div className="audience-icon yellow">👩‍🏫<p>Professores e profissionais de educação</p></div>

                        </div>
                        <div className="audience-card">
                            <div className="audience-icon red">👨‍💻<p>Profissionais de T.I, Startups e Empresas de Base Tecnológica</p></div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Seção de público-alvo */}

        </div>
    );
}

export default InovacaoCapacitacao;